import './App.scss';
import { useEffect, useState } from 'react';
import Login from './Pages/Login/Login';
import reactRouterDom, { Route, Router, Switch, useHistory   } from 'react-router-dom';
import LandingPage from './Pages/LandingPage/LandingPage';
import Dashboard from './Pages/LandingPage/Dashboard';
import { Flow } from './utils/flow';
import authenticationService from './services/authenticationService';
import jsUtils from './utils/jsUtils';
import { CircularProgress } from '@mui/material';
import ToastMessage, { MESSAGE_TYPE } from './components/ToastMessage';
import axios from 'axios';
import config from './config';

function App() {

  const [userLoggedIn, setUserLoggedIn]=useState(false);
  const [flow,setFlow]=useState(null);
  const [loading,setLoading]=useState(false);
  const [message,setMessage]=useState('');
  const [messageType, setMessageType] = useState('error');
  const history = useHistory();

  const getSamlResponse=async (flowId,sid)=>{
    await authenticationService.samlResume(flowId,sid).then((response)=>{
      if(response?.data && !(response?.data instanceof Error) && response?.data?.data){
        let htmlFormat=response?.data?.data;
        htmlFormat = htmlFormat.replace(/\'/g, "'").replace(/\"/g, '"').replace(/\r\t/g, "").replace(/\r\n/g, "").replace(/\n/g, "");
        document.open();
        document.write(htmlFormat);
        document.close();
      }
      else{
        console.log("error fetching saml response");
        setUserLoggedIn(true);
        if(window.location.href?.split('?')?.[1] === "isAuthorized=false") {
          history.push("/");
        }
        window.history.replaceState({},"","/");
      }
    }).catch(()=>{
      console.log("error fetching saml response")
    })
  }
  const onLoginSuccess = async (sid,timeOut="",samlFlowIdFromStore) => {
    localStorage.setItem('sid',sid);
    localStorage.setItem('timeOut',timeOut);
    sessionStorage.setItem('timeOut',timeOut);
    localStorage.setItem("isAuthorized", true);

    //console.log('SID being sent to Parent');  
    const sidInfo = {
                      "sid": sid
                    };
     
    //window.opener.postMessage(sidInfo, '*'); //Need to change the target window in QA and above
    const popup =  window.opener;
    //const urlPath = (window.location != window.parent.location) ? document.referrer: document.location;
    if (popup){  
      if(config.addInDomainUrl)   {
         popup.postMessage(sidInfo,`${config.addInDomainUrl}`);
      }
    }
    // else{
    //   // window.postMessage(sidInfo, 'https://localhost:3000');
    //   window.postMessage(sidInfo, `${config.addInDomainUrl}`);
    // } 
  const TargetUrlPresent = checkTargetUrl();
  if (TargetUrlPresent) {
    // Open target URL in a new tab with SID
    window.open(TargetUrlPresent,"_self");
    setUserLoggedIn(true);
  } else {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const flowId = urlParams.get("flowId") || samlFlowIdFromStore;
    if (flowId && flowId !== "undefined") {
      getSamlResponse(flowId, sid);
    } else {
      setUserLoggedIn(true);
      if (window.location.href?.split("?")?.[1] === "isAuthorized=false") {
        history.push("/");
      }
      window.history.replaceState({}, "", "/");
    }
  }
  
  } 
  
  const updateFlow=(_flow)=>{
    setFlow(new Flow(_flow));
  }

  const handleClose=()=>{
    setMessage('');
  }

  const showToastMessage = (message, type = MESSAGE_TYPE.ERROR) => {
    setMessage(message);
    setMessageType(type);
  }
  const checkTargetUrl = () => {
    // Decode the URL search parameters from the current URL (the window's search string)
    const decodedLink = window.location.search;
    const decodedParams = new URLSearchParams(decodedLink);
  
    // Get the target_url parameter value from the query string
    const isTargetUrlParam = decodedParams.get("target_url");
  
    if (isTargetUrlParam) {
      let targetUrl = isTargetUrlParam;
  
      // Check if target_url already contains query parameters
      if (targetUrl.indexOf('?') === -1) {
        // If no query parameters exist, append '?' to start the query string
        targetUrl += '?';
      } else {
        // If '?' is already present, append '&' to add new parameters
        targetUrl += '&';
      }
  
      // Append sid and gmt_offset as new query parameters
      const sid = encodeURIComponent(localStorage.getItem("sid"));
      const gmtOffset = encodeURIComponent(jsUtils.getGMTOffset());
  
      targetUrl += `sid=${sid}&gmt_offset=${gmtOffset}`;
  
      // Return the modified target_url
      return targetUrl;
    }
  
    // If target_url doesn't exist in the query string, return null
    return null;
  };

  

  useEffect(async() => {    
    //handle auto logout in case of 401/403     
    if(window.location.href?.split('?')?.[1] === "isAuthorized=false") {
      if(sessionStorage.getItem('timeoutId')){
        clearInterval(sessionStorage.getItem('timeoutId'))
      }
      localStorage.clear();
      sessionStorage.clear();
      setUserLoggedIn(false);
      history.push("/");
    } 
    else if(jsUtils.getUrlParameters(window.location.href)?.['code']){
      setLoading(true);
      let code=jsUtils.getUrlParameters(window.location.href)?.['code']
      let appId=jsUtils.getUrlParameters(window.location.href)?.['appid'];
      const flowId=localStorage.getItem('samlFlowId')
      let payload={'code':code}
      if(appId){
        payload['appId']=appId;
      }
      await axios.post(`${config.ssoApiBaseUrl}/sso/v2/login/code`,payload,{withCredentials:true}).then((response)=>{
          console.log("log user in");
          if(response.data && !(response.data instanceof Error)){
            if(response.data?.data?.mobileDeeplink){
              window.location.replace(response.data?.data?.mobileDeeplink)
            }
            else{
              window.history.replaceState({},"","/");
              localStorage.removeItem('samlFlowId');
              onLoginSuccess(response.data?.data?.sid,response.data?.data?.timeOut,flowId);
            }

          }
          else{
            showToastMessage('invalid code');
            console.log('not a valid code');
          }
        }).catch((err)=>{
          console.log(err);
        }).finally(()=>{
          setLoading(false);
        })
    }
    else if(localStorage.getItem("sid") && localStorage.getItem("isAuthorized")) {
       const queryString = window.location.search;
      const TargetUrlPresent= checkTargetUrl();
       if (TargetUrlPresent) {
         // Open target URL in a new tab with SID
        window.open(TargetUrlPresent,"_self");
        setUserLoggedIn(true);
      } 
      else{
      const urlParams = new URLSearchParams(queryString);
      const flowId=urlParams.get('flowId');
        if(flowId){
          window.history.replaceState({},"","/");
          getSamlResponse(flowId,localStorage.getItem("sid"));
        }
        else{
          let timeOut=localStorage.getItem("timeOut");
          if(timeOut && Number(timeOut)){
            sessionStorage.setItem("timeOut",timeOut)
          }
          window.history.replaceState({},"","/");
          setUserLoggedIn(true);
        }
      }
      }
      else{
        setUserLoggedIn(false);
      }
  },[]);  

  return (
    <div data-testid="App">
      <ToastMessage message={message} type={messageType} onClose={handleClose} />
      {loading?<CircularProgress sx={{display:'block',position:'fixed',top:"50%",right:'50%'}} />:userLoggedIn ?
        <Switch>
          <Route path="/landing" render={(routeProps) => <LandingPage setUserLoggedIn={setUserLoggedIn} />} />
            <Route path="/Dashboard" render={(routeProps) => <Dashboard/>} />
            <Route path="/" exact render={(routeProps) => <LandingPage setUserLoggedIn={setUserLoggedIn} />} />
        </Switch>
        : <Login onLoginSuccess={onLoginSuccess} setUserLoggedIn={setUserLoggedIn} parentFlow={flow} updateFlow={updateFlow} />
      }
    </div>
  );
}

export default App;
